"use strict";
/// <reference types="@eonasdan/tempus-dominus"/>
Object.defineProperty(exports, "__esModule", { value: true });
exports.Datepicker = void 0;
var tempus_dominus_1 = require("@eonasdan/tempus-dominus");
var fontawesome_svg_core_1 = require("@fortawesome/fontawesome-svg-core");
var free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
// import * as localeRu from '@eonasdan/tempus-dominus/src/js/';
/* import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/fontawesome'; */
require("@eonasdan/tempus-dominus/src/scss/tempus-dominus");
var data_1 = require("../utils/data");
var Datepicker = /** @class */ (function () {
    function Datepicker(config) {
        this.options = {};
        this.element = config.element;
        Object.assign(this.options, this.optionsFromDataAttributes());
        if (config.options) {
            Object.assign(this.options, config.options);
        }
        this.initFontAwersome();
        // loadLocale(TempusDominus.locales.ru);
        this.instance = new tempus_dominus_1.TempusDominus(this.element, this.options);
        // this.instance.locale(TempusDominus.locales.ru.name);
        return this;
    }
    Datepicker.prototype.optionsFromDataAttributes = function () {
        var options = {};
        Object.assign(options, this.element.dataset);
        for (var optionsKey in options) {
            options[optionsKey] = data_1.Data.getData(options[optionsKey]);
        }
        return options;
    };
    Datepicker.prototype.initFontAwersome = function () {
        fontawesome_svg_core_1.library.add(free_solid_svg_icons_1.faClock, free_solid_svg_icons_1.faCalendar, free_solid_svg_icons_1.faArrowUp, free_solid_svg_icons_1.faArrowDown, free_solid_svg_icons_1.faChevronLeft, free_solid_svg_icons_1.faChevronRight, free_solid_svg_icons_1.faCalendarCheck, free_solid_svg_icons_1.faTrash, free_solid_svg_icons_1.faTimes);
        fontawesome_svg_core_1.dom.watch();
    };
    Datepicker.prototype.getInstance = function () {
        return this.instance;
    };
    return Datepicker;
}());
exports.Datepicker = Datepicker;
