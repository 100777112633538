"use strict";
/// <reference types="jquery"/>
/// <reference types="fancybox"/>
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModalImage = void 0;
require("@fancyapps/fancybox/dist/jquery.fancybox.js");
require("@fancyapps/fancybox/dist/jquery.fancybox.css");
var index_1 = require("./index");
var ModalImage = /** @class */ (function (_super) {
    __extends(ModalImage, _super);
    function ModalImage(config) {
        var _this = _super.call(this, config) || this;
        if (config.elementsIngoreSelector) {
            _this.elementsIngoreSelector = config.elementsIngoreSelector;
        }
        return _this;
    }
    ModalImage.prototype.run = function () {
        if (this.elementsIngoreSelector) {
            jQuery(this.elementsSelector)
                .not(this.elementsIngoreSelector)
                .fancybox(this.options);
        }
        else {
            _super.prototype.run.call(this);
        }
    };
    return ModalImage;
}(index_1.Modal));
exports.ModalImage = ModalImage;
