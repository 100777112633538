"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScrollTo = void 0;
var ScrollTo = /** @class */ (function () {
    function ScrollTo(config) {
        var _this = this;
        this.step = function (timestamp) {
            var progress = Math.min((timestamp - _this.start) / _this.scrollDuration, 1);
            _this.setScrollTop(_this.initScrollTop - Math.round(_this.ease(progress) * _this.pxsToScrollBy));
            if (progress < 1) {
                requestAnimationFrame(_this.step);
            }
        };
        this.triggerElement = config.triggerElement;
        this.destination = config.destination;
        this.ease = config.ease;
        if (config.scrollContainer) {
            this.scrollContainer = config.scrollContainer;
        }
        else {
            this.scrollContainer = document.body;
        }
        if (config.scrollDuration) {
            this.scrollDuration = config.scrollDuration;
        }
        else {
            this.scrollDuration = 100;
        }
        if (config.ease) {
            this.ease = config.ease;
        }
        this.scrollDocumentElement = (this.scrollContainer === document.body) && document.documentElement;
        this.start = 0;
        this.initScrollTop = 0;
        this.pxsToScrollBy = 0;
    }
    ScrollTo.prototype.run = function () {
        this.initEventListeners();
    };
    ScrollTo.prototype.initEventListeners = function () {
        var _this = this;
        if (this.triggerElement) {
            this.triggerElement.addEventListener('click', function (event) { return _this.clickHandler(event); });
        }
    };
    ScrollTo.prototype.clickHandler = function (event) {
        this.scroll();
    };
    ScrollTo.prototype.scroll = function () {
        var scrollTo = typeof this.destination === 'function' ? this.destination() : this.destination.offsetTop;
        var performance = window.performance, requestAnimationFrame = window.requestAnimationFrame;
        if (this.scrollDuration <= 0 || typeof performance === 'undefined' || typeof requestAnimationFrame === 'undefined' || !this.ease) {
            return this.setScrollTop(scrollTo);
        }
        this.start = performance.now();
        this.initScrollTop = this.getScrollTop();
        this.pxsToScrollBy = this.initScrollTop - scrollTo;
        requestAnimationFrame(this.step);
        return;
    };
    ScrollTo.prototype.getScrollTop = function () {
        return this.scrollContainer.scrollTop || (this.scrollDocumentElement && document.documentElement.scrollTop || 0);
    };
    ScrollTo.prototype.setScrollTop = function (value) {
        this.scrollContainer.scrollTop = value;
        if (this.scrollDocumentElement) {
            document.documentElement.scrollTop = value;
        }
    };
    return ScrollTo;
}());
exports.ScrollTo = ScrollTo;
