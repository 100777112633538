"use strict";
/// <reference types="jquery"/>
/// <reference types="fancybox"/>
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModalForm = void 0;
require("@fancyapps/fancybox/dist/jquery.fancybox.js");
require("@fancyapps/fancybox/dist/jquery.fancybox.css");
var ModalForm = /** @class */ (function () {
    function ModalForm(config) {
        var _this = this;
        var formOptions = {
            type: 'ajax',
            clickContent: false,
            clickSlide: false,
            clickOutside: false,
            afterShow: function (instance, current) {
                var container = document.querySelector('.modal__form-container');
                if (container !== null) {
                    _this.form = container.querySelector('form');
                    _this.initValidation();
                }
            }
        };
        this.elementsSelector = config.elementsSelector;
        if (config.options) {
            this.options = config.options;
        }
        this.options = jQuery.extend(true, this.options, formOptions);
    }
    ModalForm.prototype.run = function () {
        jQuery(this.elementsSelector).fancybox(this.options);
    };
    ModalForm.prototype.initValidation = function () {
        if (this.form !== null) {
        }
    };
    return ModalForm;
}());
exports.ModalForm = ModalForm;
