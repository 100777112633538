"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Toggler = void 0;
require("classlist-polyfill");
var Toggler = /** @class */ (function () {
    function Toggler(config) {
        this.config = config;
        this.toggleClassName = config.toggleClassName ? config.toggleClassName : 'd-block';
        this.initialElementText = this.config.element.innerText;
    }
    Toggler.prototype.run = function () {
        this.initEventListeners();
    };
    Toggler.prototype.initEventListeners = function () {
        var _this = this;
        if (this.config.element && this.config.target) {
            this.config.element.addEventListener('click', function (event) { return _this.clickElementHandler(event); });
        }
    };
    Toggler.prototype.clickElementHandler = function (event) {
        var element = event.target;
        event.preventDefault();
        if (this.config.target.classList.contains(this.toggleClassName)) {
            this.config.target.classList.remove(this.toggleClassName);
            if (this.config.elementSwitchedClassname && element.classList.contains(this.config.elementSwitchedClassname)) {
                element.classList.remove(this.config.elementSwitchedClassname);
            }
            if (this.config.elementSwitchedText) {
                element.innerText = this.initialElementText;
            }
            if (this.config.switchOff) {
                this.config.switchOff();
            }
        }
        else {
            this.config.target.classList.add(this.toggleClassName);
            if (this.config.elementSwitchedClassname) {
                element.classList.add(this.config.elementSwitchedClassname);
            }
            if (this.config.elementSwitchedText) {
                element.innerText = this.config.elementSwitchedText;
            }
            if (this.config.switchOn) {
                this.config.switchOn();
            }
        }
        if (this.config.removeElement) {
            element.remove();
        }
    };
    return Toggler;
}());
exports.Toggler = Toggler;
