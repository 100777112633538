"use strict";
/// <reference types="waypoints"/>
Object.defineProperty(exports, "__esModule", { value: true });
require("waypoints/lib/noframework.waypoints.js");
require("core-js/stable/dom-collections/for-each");
require("classlist-polyfill");
require("element-closest-polyfill");
require("bootstrap/js/dist/collapse");
require("bootstrap/js/dist/alert");
var ease_component_1 = require("ease-component");
var form_1 = require("./modals/form");
var image_1 = require("./modals/image");
var media_1 = require("./modals/media");
var scroll_to_1 = require("./scroll-to");
var toggler_1 = require("./toggler");
var datepicker_1 = require("./datepicker");
require("./../scss/_variables");
require("bootstrap/scss/bootstrap");
require("./../scss/main");
var Application = /** @class */ (function () {
    function Application() {
    }
    Application.prototype.run = function () {
        this.initModalForm();
        this.initModalImage();
        this.initModalMedia();
        this.initToggler();
        this.initScrollTo();
        this.initWaypoints();
        this.initDatepicker();
    };
    Application.prototype.initModalForm = function () {
        new form_1.ModalForm({
            elementsSelector: '.modal-form__opener'
        }).run();
    };
    Application.prototype.initModalImage = function () {
        new image_1.ModalImage({
            elementsSelector: '.j-popup-image',
            options: {
                smallBtn: true
            }
        }).run();
    };
    Application.prototype.initModalMedia = function () {
        new media_1.ModalMedia({
            elementsSelector: '.fancybox__media'
        }).run();
    };
    Application.prototype.initToggler = function () {
        var _this = this;
        var items = document.querySelectorAll('.j-toggler-btn');
        items.forEach(function (item) {
            var element = item;
            if (!element.dataset.target) {
                return;
            }
            var target = document.querySelector(element.dataset.target);
            if (target === null) {
                return;
            }
            if (element.dataset.locked) {
                _this.initTogglerWithLockedBody(element, target);
            }
            else {
                _this.initTogglerDefault(element, target);
            }
        });
    };
    Application.prototype.initTogglerWithLockedBody = function (trigger, target) {
        var body = document.querySelector('body');
        new toggler_1.Toggler({
            element: trigger,
            target: target,
            removeElement: (trigger.dataset.removeTrigger !== undefined),
            elementSwitchedText: trigger.dataset.switchedText,
            elementSwitchedClassname: trigger.dataset.switchedClassName,
            switchOn: function () {
                if (body !== null) {
                    var overlay = document.createElement('div');
                    overlay.className = 'overlay';
                    body.appendChild(overlay);
                    body.classList.add('body-locked');
                }
            },
            switchOff: function () {
                if (body !== null) {
                    var overlay = document.querySelector('.overlay');
                    if (overlay !== null) {
                        body.removeChild(overlay);
                    }
                    body.classList.remove('body-locked');
                }
            }
        }).run();
    };
    Application.prototype.initTogglerDefault = function (trigger, target) {
        new toggler_1.Toggler({
            element: trigger,
            target: target,
            removeElement: (trigger.dataset.removeTrigger !== undefined),
            elementSwitchedText: trigger.dataset.switchedText,
            elementSwitchedClassname: trigger.dataset.switchedClassName,
        }).run();
    };
    Application.prototype.initScrollTo = function () {
        var items = document.querySelectorAll('.j-scroll-btn');
        items.forEach(function (item) {
            var element = item;
            if (element.dataset.scrollHref) {
                var scrollTarget = document.querySelector(element.dataset.scrollHref);
                if (scrollTarget !== null) {
                    new scroll_to_1.ScrollTo({
                        triggerElement: item,
                        destination: scrollTarget,
                        ease: ease_component_1.inOutSine,
                        scrollDuration: element.dataset.scrollDuration ? parseInt(element.dataset.scrollDuration) : 1000
                    }).run();
                }
            }
        });
    };
    Application.prototype.initWaypoints = function () {
        var items = document.querySelectorAll('.j-waypoint-target');
        items.forEach(function (item) {
            var element = item;
            if (!element.dataset.offsetElement) {
                return;
            }
            var offsetElement = document.querySelector(element.dataset.offsetElement);
            if (offsetElement === null) {
                return;
            }
            new Waypoint({
                element: offsetElement,
                offset: function () {
                    return -offsetElement.offsetHeight;
                },
                handler: function (direction) {
                    if (direction == 'down') {
                        element.classList.add('d-block');
                    }
                    else {
                        element.classList.remove('d-block');
                    }
                }
            });
        });
    };
    Application.prototype.initDatepicker = function () {
        var items = document.querySelectorAll('.j-datepicker');
        items.forEach(function (item) {
            new datepicker_1.Datepicker({
                element: item
            });
        });
    };
    return Application;
}());
document.addEventListener('DOMContentLoaded', function () {
    new Application().run();
});
