"use strict";
/// <reference types="jquery"/>
/// <reference types="fancybox"/>
Object.defineProperty(exports, "__esModule", { value: true });
exports.Modal = void 0;
require("@fancyapps/fancybox/dist/jquery.fancybox.js");
require("@fancyapps/fancybox/dist/jquery.fancybox.css");
var Modal = /** @class */ (function () {
    function Modal(config) {
        this.langs = {
            lang: "ru",
            i18n: {
                ru: {
                    CLOSE: "Закрыть",
                    NEXT: "Следующий",
                    PREV: "Предыдущий",
                    ERROR: "Ошибка загрузки данных. <br/> Пожалуйста попробуйте позже.",
                    PLAY_START: "Старт",
                    PLAY_STOP: "Пауза",
                    FULL_SCREEN: "На весь экран",
                    THUMBS: "Превью",
                    DOWNLOAD: "Скачать",
                    SHARE: "Share",
                    ZOOM: "Поделиться"
                }
            }
        };
        this.elementsSelector = config.elementsSelector;
        if (config.options) {
            this.options = config.options;
        }
        this.options = jQuery.extend(true, this.options, this.langs);
    }
    Modal.prototype.run = function () {
        jQuery(this.elementsSelector).fancybox(this.options);
    };
    return Modal;
}());
exports.Modal = Modal;
